import {LocalStorage} from '@/util/local-storage';
import {useSyncExternalStore, JSX} from 'react';

import {ObservableVar} from '@/util/observable-var';

type DebugFlags = {
    // In the active patients list, allow to show the overlays even when there is nothing to show in them
    alwaysShowTrendsOverlays: boolean;
    // Do not show the small screen view when the viewport is too small
    allowSmallScreen: boolean;
    // Use strict mode (works only in dev mode)
    useStrictMode: boolean;
    // Indicates to show the changelog gift button, even when no change have been added
    forceShowChangelogGift: boolean;

    // Force the usage of the Demo patient data in the privacy site.
    // This will NOT allow the sumbission fo the forms, but helps in development of the forms render
    privacyDemoPatient: boolean;
};

/**
 * This service is used internally to enable debug flags, so developers can debug things more easily.
 */
// noinspection JSUnusedGlobalSymbols
class DebugService_ {
    debugFlags: ObservableVar<DebugFlags>;

    constructor() {
        this.debugFlags = new ObservableVar<DebugFlags>(JSON.parse(LocalStorage.get('debug_flags', JSON.stringify({
            alwaysShowTrendsOverlays: false,
        } as DebugFlags))));

        this.debugFlags.onChange(() => {
            LocalStorage.set('debug_flags', JSON.stringify(this.debugFlags.value, null, '  '));
        });
    }

    alwaysShowTrendsOverlays(value: boolean) {
        this.debugFlags.set({
            ...this.debugFlags.value,
            alwaysShowTrendsOverlays: value,
        });
    }

    allowSmallScreen(value: boolean) {
        this.debugFlags.set({
            ...this.debugFlags.value,
            allowSmallScreen: value,
        });
    }

    useStrictMode(value: boolean) {
        this.debugFlags.set({
            ...this.debugFlags.value,
            useStrictMode: value,
        });
    }

    async showChangelogGift(value: boolean) {
        this.debugFlags.set({
            ...this.debugFlags.value,
            forceShowChangelogGift: value,
        });
    }

    async usePrivacyDemoPatient(value: boolean) {
        this.debugFlags.set({
            ...this.debugFlags.value,
            privacyDemoPatient: value,
        });
    }
}

function useDebugFlags() {
    return useSyncExternalStore<DebugFlags>(
        DebugService.debugFlags.onChange,
        () => DebugService.debugFlags.value,
    );
}

type Props = { [key: string]: unknown };
function withDebugFlags(Component: JSX.ElementType) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function ComponentWithDebugFlags(props: Props) {
        const debugFlags = useDebugFlags();

        const extraProps: Props = {};
        extraProps['debugFlags'] = debugFlags;

        return (
            <Component {...props} {...extraProps} />
        );
    }

    return ComponentWithDebugFlags;
}

const DebugService = new DebugService_();

(window as unknown as { DebugService: DebugService_ }).DebugService = DebugService;

export { DebugService, useDebugFlags, withDebugFlags };
export type { DebugFlags };
